import { useState, useRef, useCallback } from "react";
import { getCoursesCategory, getServicesCategory } from "../ApiServices/HomePageController";

export default function useFetchData() {
  const [services, setServices] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Caches to avoid redundant API calls
  const servicesCache = useRef([]);
  const coursesCache = useRef({});

  // Fetch services (only if not cached)
  const fetchServices = useCallback(async () => {
    if (servicesCache.current.length > 0) {
      setServices(servicesCache.current);
      return;
    }
    setLoading(true);
    try {
      const response = await getServicesCategory();
      if (response && response.data.status === "OK") {
        servicesCache.current = response.data.details;
        setServices(response.data.details);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch courses by category (only if not cached)
  const fetchCoursesByCategory = useCallback(async (categoryId) => {
    if (coursesCache.current[categoryId]) {
      setCourses(coursesCache.current[categoryId]);
      return;
    }
    setLoading(true);
    try {
      const response = await getCoursesCategory(categoryId);
      if (response && response.data.status === "OK" && response.data.details.length > 0) {
        coursesCache.current[categoryId] = response.data.details;
        setCourses(response.data.details);
      } else {
        setCourses([]);
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    services,
    courses,
    loading,
    error,
    fetchServices,
    fetchCoursesByCategory,
  };
}
