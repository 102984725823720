import { ApiHelper } from "./ApiHelper";
import * as ApiConstants from "./ApiConstants.js";

export const getCategories = (data) => {
  
  return ApiHelper.api().post(ApiConstants.CATEGORY , data);
};
export const getCoursesCategory = (category_id) => {
  
  return ApiHelper.api().get(
    ApiConstants.COURSES_CATEGORY  + "&category_id=" + category_id
  );
};
export const getServicesCategory = () => {
  
  return ApiHelper.api().post(ApiConstants.SERVICES_CATEGORY );
};
export const getPopularCourses = () => {
  
  return ApiHelper.api().get(ApiConstants.POPULAR_COURSES );
};
export const getCoursesDetails = (course_id) => {
  
  return ApiHelper.api().get(
    ApiConstants.COURSES_DETAILS  + "&course_id=" + course_id
  );
};
export const getSubjectTopics = (subject_id) => {
  
  return ApiHelper.api().get(
    ApiConstants.SUBJECT_TOPICS  + "&subject_id=" + subject_id
  );
};
export const getCoursesPlans = (category_id) => {
  
  return ApiHelper.api().get(
    ApiConstants.COURSES_PLANS  + "&category_id=" + category_id
  );
};

export const sendMail = (data) => {
     console.log('dataaa',data);
  return ApiHelper.api().post(ApiConstants.SEND_MAIL, data);
};
